import React from 'react';
import './About.css';
import HTML from '../assets/html.png';
import Python from '../assets/python.png';
import TailwindCSS from "../assets/tailwind-css.png"
import DataGrip from "../assets/DataGrip.png"
import PyCharm from "../assets/PyCharm.png"
import Telegram from "../assets/telegram2.png"
import WebStorm from "../assets/WebStorm.png"
import ReactIcon from '../assets/react.png'
import FastAPI from '../assets/FastAPI.png'
import NPMlogo from '../assets/npm.png'
import GitLogo from '../assets/git.png'
import GitHublogo from '../assets/github.png'
import GMeet from '../assets/meet.png'
import Zoomlogo from '../assets/zoom.webp'
import WebPack from '../assets/webpack.png'
import PostGres from '../assets/postgres.png'
import CDT from '../assets/cdt.png'
import TypeScript from '../assets/ts-logo-512.png'
import PostMan from '../assets/postman.png'

const About = () => (
  <section className="about" id="about">
    <div className="about-me">
      <h2 className="title about-title">aBOUT mE</h2>
      <p className="paragraph about-para">
        My journey in the world of programming began in 2017, when I ventured into freelancing. Since then, I had the opportunity to work on a wide range of projects, honing my skills and expanding my knowledge along the way. I have worked with various clients, both independently and as part of a team, to deliver innovative and user-friendly websites and mobile applications.
      </p>
    </div>
    <div className="about-skills">
      <h2 className="skill-title">Front-End</h2>
      <div className="skills">
        <div className="skill">
          <img
            src={ReactIcon}
            alt=""
          />
          <p>React</p>
        </div>
        <div className="skill">
          <img
            src={TypeScript}
            alt=""
          />
          <p>TypeScript</p>
        </div>
        <div className="skill">
          <img
            src={HTML}
            alt=""
          />
          <p>HTML5</p>
        </div>
        <div className="skill">
          <img
            src={TailwindCSS}
            alt=""
          />
          <p>TailwindCSS</p>
        </div>
      </div>
      <h2 className="skill-title">Back-End</h2>
      <div className="skills">
        <div className="skill">
          <img
              src={FastAPI}
              alt=""
          />
          <p>FastAPI</p>
        </div>
        <div className="skill">
          <img
              src={PostGres}
              alt=""
          />
          <p>PostgreSQL</p>
        </div>
        <div className="skill">
          <img
              src={Telegram}
              alt=""
          />
          <p>Telegram Bot | WebAPP</p>
        </div>
        <div className="skill">
          <img
              src={Python}
              alt=""
          />
          <p>Python</p>
        </div>
      </div>
      <h2 className="skill-title">Tools</h2>
      <div className="skills">
        <div className="skill">
          <img src={GitLogo} alt=""/>
          <p>Git</p>
        </div>
        <div className="skill">
          <img src={GitHublogo} alt=""/>
          <p>GitHub</p>
        </div>
        <div className="skill">
          <img src={CDT} alt=""/>
          <p>Chrome Dev Tools</p>
        </div>
        <div className="skill">
          <img src={PyCharm} alt=""/>
          <p>PyCharm</p>
        </div>
        <div className="skill">
          <img src={DataGrip} alt=""/>
          <p>DataGrip</p>
        </div>
        <div className="skill">
          <img src={WebStorm} alt=""/>
          <p>WebStorm</p>
        </div>
        <div className="skill">
          <img src={WebPack} alt=""/>
          <p>Webpack</p>
        </div>
        <div className="skill">
          <img src={PostMan} alt=""/>
          <p>Postman</p>
        </div>
        <div className="skill">
          <img src={NPMlogo} alt=""/>
          <p>npm</p>
        </div>
      </div>
      <h2 className="skill-title">Communication</h2>
      <div className="skills">
        <div className="skill">
          <img src={GMeet} alt="" />
          <p>Google Meet</p>
        </div>
        <div className="skill">
          <img src={Zoomlogo} alt="" />
          <p>Zoom</p>
        </div>
      </div>
      <h2 className="skill-title">Soft Skills</h2>
      <div className="skills">
        <p className="soft-skill">Remote Pair-Programming</p>
        <p className="soft-skill">Communication</p>
        <p className="soft-skill">Mentoring</p>
        <p className="soft-skill">Collaboration</p>
        <p className="soft-skill">Leadership</p>
        <p className="soft-skill">Problem Solving</p>
        <p className="soft-skill">Time Management</p>
        <p className="soft-skill">Decision Making</p>
        <p className="soft-skill">Critical Thinking</p>
      </div>
    </div>

  </section>
);

export default About;
