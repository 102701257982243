import React from 'react';
import './Contact.css';

const Contact = () => (
  <div className="contact" id="contact">
    <div className="footer-left">
      <h1>
        I LOVE
        {' '}
        <br />
        INTERESTING
        <br />
        {' '}
        PROJECTS
      </h1>
    </div>
    <div className="footer-right">
      <h3>I'M ALWAYS INTERESTED ABOUT</h3>
      <div className="interests">
        <p>Frontend Development</p>
        <p>React</p>
        <p>Backend Development</p>
        <p>Startups</p>
        <p>New Opportunities</p>
        <p>Telegram Bot Development</p>
      </div>

      <hr />

      <h3>
        MINDING A PROJECT?
        <span><a>cOnTaCt mE</a></span>
      </h3>

      <hr />

      <div className="social">
        <span><a href="https://t.me/work_george" target="_blank" rel="noreferrer">TELEGRAM</a></span>
      </div>
    </div>
  </div>
);

export default Contact;
